import { Box, Button, LinearProgress, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import App from 'AppInterface/App';
import { Icons } from 'config/icons';
import { deleteFile, getStorageUrl, uploadFile } from 'fb/storage';
import { User } from 'firebase/auth';
import { getContentStoragePath, getUserLocalPhoto, getWebAppAssetPath, isAdmin, isAndroid, isApple, isMobile, toDataUrl } from 'helpers';
import { Context } from 'helpers/context';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { updateProfile } from 'services/api/auth';
import { EventType, onEvent } from 'store/temp/actions';
import { ApplicationState, ContentType, UserData } from 'types';
import imageCompression from 'browser-image-compression';

const classes = {
	picsection: {
		paddingBottom: 2,
		paddingTop: 2,
	},
	userpicNA: (theme) => ({
		width: '100%',
		fontSize: 80,
		color: theme.palette.grey[400],
	}),
	userpic: {
		width: 96,
		height: 96,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 1,
	},
	pic: { position: 'relative' },
	editPic: {
		width: 96,
		height: 96,
		textAlign: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1,
		position: 'absolute',
		top: 0,
		left: 'calc(50% - 48px)',
	},
};

interface Props {
	user?: User;
	userData?: UserData;
	allowChange?: boolean;
	updatePic: (photoUrl: string) => Promise<void>;
	showMessage: (...payload) => void;
}

const Component = (props: Props) => {
	let { user, userData, allowChange, updatePic, showMessage } = props;

	const [,] = useReducer((x) => x + 1, 0);
	const [picUploadProgress, setProgress] = useState(0.0);
	const [showProgress, setShowProgress] = useState(false);
	const [photoLocalUrl, setPhotoLocalUrl] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (user) {
			let photoUrl =
				(userData?.photoUrl && userData.photoUrl.length > 1 ? userData.photoUrl : undefined) || user?.photoURL || getWebAppAssetPath('app_icon.png');

			getUserLocalPhoto(user.uid, photoUrl).then((dataUrl) => {
				setPhotoLocalUrl(dataUrl || undefined);
			});
		}
	}, []);

	if (!user) {
		return null;
	}

	let androidBuildVersion = isAndroid() ? (App.getBuildVersionCode as any as () => number)() : 0;
	// allowChange = allowChange && (androidBuildVersion >= 45 || isApple() || !isMobile());

	let photoUrl = (userData?.photoUrl && userData.photoUrl.length > 1 ? userData.photoUrl : undefined) || user?.photoURL || getWebAppAssetPath('app_icon.png');

	let phone = user.phoneNumber;

	let name = 'userpic';

	// userData!.fullName = 'Abc Xyz';
	// userData!.email = 'abc@xyz.com';
	// userData!.roles = [];
	// phone = '+911234567890';

	return (
		<Paper sx={classes.picsection} square>
			<Box sx={classes.pic}>
				{allowChange && (
					<Box sx={classes.editPic}>
						<div style={{ fontSize: '1.6rem' }}>
							<input
								accept="image/*"
								name={name + '_FileInput'}
								type="file"
								id={'file-upload-' + name}
								style={{
									fontSize: '1.6rem',
									borderBottom: '1px solid grey',
									width: '100%',
									display: 'none',
								}}
								onChange={async (event) => {
									if (event?.currentTarget?.files && event?.currentTarget?.files.length > 0) {
										let imageFile = event?.currentTarget?.files[0];
										console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
										console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

										const options = {
											maxSizeMB: 0.25,
											// maxWidthOrHeight: 1920,
											useWebWorker: false,
											maxIteration: 20,
										};
										try {
											setShowProgress(true);
											setProgress(0);
											if (imageFile.size > 210000) {
												imageFile = await imageCompression(imageFile, options);
												console.log('compressedFile instanceof Blob', imageFile instanceof Blob); // true
												console.log(`compressedFile size ${imageFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
											}

											let curUrl = userData?.photoUrl;
											if (curUrl && curUrl.length > 1) {
												let segments = curUrl.split('?')[0].split('/');
												let oldPath = segments[segments.length - 1].replace(/%2F/g, '/');

												await deleteFile(oldPath);
											}

											let path = getContentStoragePath(ContentType.User, user?.uid, imageFile);
											await uploadFile(
												path,
												imageFile,
												(progress) => {
													setProgress(progress);
												},
												false
											);

											let url = getStorageUrl(path);
											await updatePic(url);
											setShowProgress(false);

											console.log(url);

											getUserLocalPhoto(user!.uid, url, true).then((dataUrl) => {
												setPhotoLocalUrl(dataUrl || undefined);
											});
										} catch (error) {
											console.log(error);
											setShowProgress(false);
											showMessage(new Context(), EventType.Information, 'changePic', {
												success: false,
												message: error,
											});
										}

										// let file = event?.currentTarget?.files[0];

										// if (file.size > 210000) {
										// 	showMessage(new Context(), EventType.Information, 'changePic', {
										// 		success: false,
										// 		message: 'File size should be less than 200KB.',
										// 	});
										// 	return;
										// }
									}
								}}
							/>
							<div style={{ display: 'inline-flex', columnGap: 3 }}>
								<label htmlFor={'file-upload-' + name}>
									<Button style={{ top: 69, minWidth: 0, padding: 8 }} variant="contained" color="primary" component="span">
										<Icons.EditIcon fontSize="small" />
									</Button>
								</label>
								{userData?.photoUrl && userData.photoUrl.length > 1 ? (
									<Button
										style={{ top: 69, minWidth: 0, padding: 8 }}
										variant="contained"
										component="span"
										onClick={async () => {
											await updatePic('.');

											getUserLocalPhoto(user!.uid, user?.photoURL || getWebAppAssetPath('app_icon.png'), true).then((dataUrl) => {
												setPhotoLocalUrl(dataUrl || undefined);
											});
										}}
									>
										<Icons.DeleteIcon fontSize="small" />
									</Button>
								) : null}
							</div>
							{/* {showProgress && (
								<Box className="blinking" sx={{ fontWeight: 'bold' }}>
									Uploading...
								</Box>
							)} */}
						</div>
					</Box>
				)}
				{photoUrl ? (
					<Avatar src={photoLocalUrl} variant="rounded" sx={classes.userpic} />
				) : (
					<Icons.AccountBoxIcon sx={classes.userpicNA}></Icons.AccountBoxIcon>
				)}
			</Box>
			<Typography align="center" variant="h5">
				{userData?.fullName || user.displayName || ' '}
			</Typography>
			<Typography align="center" variant="subtitle1">
				{user.email ?? userData?.email}
			</Typography>
			{phone ? (
				<Typography align="center" variant="subtitle1">
					{phone}
				</Typography>
			) : null}
			{isAdmin(user, userData) ? (
				<Typography color="primary" align="center" variant="subtitle1">
					[Administrator]
				</Typography>
			) : null}
			{userData && userData.roles && userData.roles.indexOf('test') > -1 && (
				<Typography color="primary" align="center" variant="subtitle1">
					[Test]
				</Typography>
			)}
			{showProgress && (
				<LinearProgress
					style={{ marginTop: 16, marginBottom: -16 }}
					variant={picUploadProgress > 0 ? 'determinate' : 'indeterminate'}
					color="primary"
					value={picUploadProgress}
					className={picUploadProgress === 0 && showProgress ? 'blinking' : ''}
				/>
			)}
		</Paper>
	);
};

function mapStateToProps({ userState }: ApplicationState) {
	let { userStore } = userState;

	return {
		userData: userStore.userData,
		user: userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updatePic: async (photoUrl: string) => {
			await dispatch(updateProfile({ photoUrl }, new Context()));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

export const UserPicInfo = connect(mapStateToProps, mapDispatchToProps)(Component);
