import { Box, Button, Chip, TextField as CoreTextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers';
import App from 'AppInterface/App';
import { Icons } from 'config/icons';
import { uploadFile } from 'fb/storage';
import { deleteField } from 'firebase/firestore';
import { ErrorMessage, Field } from 'formik';
import { Autocomplete } from 'formik-mui';
import { DatePickerProps, fieldToDatePicker } from 'formik-mui-x-date-pickers';
import { getMediaBasePath, timeout } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import { Moment } from 'moment';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export const bull = <span style={{ display: 'inline-block', margin: '0 5px' }}>•</span>;
export const collectionTypes = {
	articles: 'Articles',
	publications: 'Publications',
	rhymes: 'Rhymes',
	booklets: 'Booklets',
};

const useStyles = makeStyles()((theme) => ({
	autoCompletePaper: {
		boxShadow: '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
	},
}));

const filter = createFilterOptions();
export const AutoCompleteField = (props: {
	name: string;
	label: string;
	options: any;
	variant?: string;
	onSelect?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value) => void;
}) => {
	let { classes } = useStyles();
	let { name, label, options, variant = 'outlined' } = props;

	return (
		<div>
			<Field
				name={name}
				component={Autocomplete}
				classes={{ paper: classes.autoCompletePaper }}
				options={options.length ? options : Object.keys(options)}
				getOptionLabel={(option) => {
					// Value selected with enter, right from the input
					if (typeof option === 'string' || typeof option === 'number') {
						let value = options.length ? option : options[option];
						if (!value) {
							return option;
						}

						if (typeof value === 'string' || typeof value === 'number') {
							return value;
						}
						return value.label;
					}
					// Add "xxx" option created dynamically
					if (option.inputValue) {
						return option.inputValue;
					}
					// Regular option
					if ((options.length && options.indexOf(option) < 0) || (!options.length && !options[option])) {
						return `Add: "${option}"`;
					}
				}}
				renderOption={(props, option) => {
					let getLabel = () => {
						if (typeof option === 'string' || typeof option === 'number') {
							if ((options.length && options.indexOf(option) < 0) || (!options.length && !options[option])) {
								return `Add: "${option}"`;
							}
							let value = options.length ? option : options[option];
							if (typeof value === 'string' || typeof value === 'number') {
								return value;
							}
							return value.render;
						}
					};
					return (
						<Box component="li" {...props}>
							{getLabel()}
						</Box>
					);
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					// Suggest the creation of a new value
					if (params.inputValue !== '') {
						filtered.push(params.inputValue);
					}

					return filtered;
				}}
				// style={{ width: 300 }}
				freeSolo
				openOnFocus
				blurOnSelect
				includeInputInList
				onInputChange={(event, value) => {
					if (props.onSelect) {
						props.onSelect(event, value);
					}
				}}
				renderInput={(params) => <CoreTextField {...params} label={label} variant={variant} />}
			/>
			<ErrorMessage name={name}>{(msg) => <p className="MuiFormHelperText-root Mui-error">{msg}</p>}</ErrorMessage>
		</div>
	);
};

export const getFileInput = (title, accept, name, values, setFieldValue, onChange?) => {
	return (
		<>
			{/* <Field disabled component={TextField} name={name} type='text' label={'Current ' + title} /> */}
			<div style={{ fontSize: '1.6rem' }}>
				<Typography
					style={{
						transform: 'translate(0, 1.5px) scale(0.75)',
						transformOrigin: 'top left',
						fontSize: '1.6rem',
					}}
					variant="body2"
					color="textSecondary"
				>
					{title}
					<span style={{ paddingLeft: 8 }}>{values[name + '_FileObj'] ? '( ' + values[name + '_FileObj'].name + ' )' : null}</span>
				</Typography>
				<input
					accept={accept}
					name={name + '_FileInput'}
					type="file"
					id={'file-upload-' + name}
					style={{
						fontSize: '1.6rem',
						borderBottom: '1px solid grey',
						width: '100%',
						display: 'none',
					}}
					onChange={async (event) => {
						if (event?.currentTarget?.files) {
							setFieldValue(name + '_FileObj', event?.currentTarget?.files[0]);
							// setFieldValue(name, event?.currentTarget?.files[0].name);
							if (onChange) {
								onChange(event);
							}
						}
					}}
				/>
				<label htmlFor={'file-upload-' + name}>
					<Button variant="contained" color="primary" component="span" startIcon={Icons.Upload}>
						Upload
					</Button>
				</label>
			</div>
			<ErrorMessage name={name + '_FileObj'}>
				{(msg) => (
					<p style={{ color: 'crimson' }} className="MuiFormHelperText-root Mui-error">
						{msg}
					</p>
				)}
			</ErrorMessage>
		</>
	);
};

const multiFileUseStyles = makeStyles()((theme) => ({
	chipRoot: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		listStyle: 'none',
		margin: '4px 0',
	},
	chip: {
		margin: '4px 8px 4px 0',
	},
}));

export const getMultiFileManagerControl = (title, accept, name, values, setFieldValue, onChange?) => {
	let { classes } = multiFileUseStyles();
	let fileObjName = name + '_MultiFileObj';

	let renderChips = (name) => {
		return (
			<div className={classes.chipRoot}>
				{Object.keys(values[name]).map((fileId) => {
					let file = values[name][fileId];
					return (
						<li key={fileId}>
							<Chip
								label={fileId + (file.fileName && !file.fileSize ? ' (size: 0)' : '')}
								clickable={!!file.storagePath}
								onClick={() => {
									App.downloadFile(getMediaBasePath() + file.storagePath + '/' + file.fileName, fileId);
								}}
								onDelete={() => {
									if (file.storagePath) {
										file.delete = !file.delete;
									} else {
										delete values[name][fileId];
									}
									setFieldValue(name, values[name]);
								}}
								// deleteIcon={file.storagePath ? Icons.Download : undefined}
								className={classes.chip}
								color={file.storagePath ? (file.delete ? 'secondary' : 'primary') : !file.fileSize ? 'secondary' : 'default'}
							/>
						</li>
					);
				})}
			</div>
		);
	};
	return (
		<>
			{/* <Field disabled component={TextField} name={name} type='text' label={'Current ' + title} /> */}
			<div style={{ fontSize: '1.6rem' }}>
				<Typography
					style={{
						transform: 'translate(0, 1.5px) scale(0.75)',
						transformOrigin: 'top left',
						fontSize: '1.6rem',
					}}
					variant="body2"
					color="textSecondary"
				>
					{title}
				</Typography>
				{values[name] ? renderChips(name) : null}
				{values[fileObjName] ? renderChips(fileObjName) : null}
				<input
					accept={accept}
					name={fileObjName}
					type="file"
					multiple
					id={'multi-file-upload-' + name}
					style={{
						fontSize: '1.6rem',
						borderBottom: '1px solid grey',
						width: '100%',
						display: 'none',
					}}
					onChange={async (event) => {
						if (event?.currentTarget?.files) {
							values[fileObjName] = values[fileObjName] ?? {};
							let files = event?.currentTarget?.files;
							if (files && files.length) {
								for (let i = 0; i < files.length; i++) {
									let file = files[i];
									let fileObj = {
										fileName: file.name,
										file: file,
										fileType: file.type,
										fileSize: file.size,
									};

									values[fileObjName][file.name] = {
										...fileObj,
										version: 0,
									};

									setFieldValue(fileObjName, values[fileObjName]);
								}
							}
							// setFieldValue(name, event?.currentTarget?.files[0].name);
							if (onChange) {
								onChange(event);
							}
						}
					}}
				/>
				<label htmlFor={'multi-file-upload-' + name}>
					<Button variant="contained" color="primary" component="span" startIcon={Icons.Upload}>
						Upload
					</Button>
				</label>
			</div>
			<ErrorMessage name={fileObjName}>
				{(msg) => (
					<p style={{ color: 'crimson' }} className="MuiFormHelperText-root Mui-error">
						{msg}
					</p>
				)}
			</ErrorMessage>
		</>
	);
};

export const uploadMultiFiles = async (recordId, name, path, values, setFieldValue, uploadProgressCallback: (progress: number, file: string) => void) => {
	let fileObjName = name + '_MultiFileObj';

	let multiFileObjs = values[fileObjName];
	values[name] = values[name] ?? {};
	let curFiles = values[name];

	if (multiFileObjs) {
		for (let multiFileObjId in multiFileObjs) {
			if (curFiles[multiFileObjId]?.delete) {
				delete curFiles[multiFileObjId];
			}

			let multiFileObj = multiFileObjs[multiFileObjId];

			if (!multiFileObj.fileSize) {
				uploadProgressCallback(5, 'Skipping zero sized file ' + multiFileObjId);
				await timeout(5000);
				continue;
			}

			let curFileName: string | undefined = curFiles[multiFileObjId]?.fileName;
			let version = curFiles[multiFileObjId]?.version ?? 0;

			let filename = multiFileObj.fileName;
			if (curFileName === filename || version) {
				version++;

				filename = version + '_' + filename;

				// await deleteFile(path + curFileName);
			}

			let storagePath = path + '/' + recordId;

			await uploadFile(
				storagePath + '/' + filename,
				multiFileObj.file,
				(progress) => {
					uploadProgressCallback(progress, multiFileObjId);
				},
				false
			);

			multiFileObj.fileName = filename;
			multiFileObj.version = version;
			multiFileObj.storagePath = storagePath;
			delete multiFileObj.file;

			values[name][multiFileObjId] = multiFileObj;
			delete values[fileObjName][multiFileObjId];
		}

		delete values[fileObjName];
	}

	if (curFiles) {
		for (let fileId in curFiles) {
			if (curFiles[fileId].delete) {
				values[name][fileId] = deleteField();
			}
		}
	}

	// setFieldValue(name, { ...values[name] });
	// setFieldValue(fileObjName, undefined);
};

export const uploadFileObj = async (recordId, name, path, values, uploadProgressCallback: (progress: number, file: string) => void, pathPrefix?) => {
	let fileObj = values[name + '_FileObj'];
	if (fileObj) {
		let extension = fileObj.name.substring(fileObj.name.lastIndexOf('.'));
		let curFileName: string | undefined = values[name];
		let version = curFileName
			?.replace(pathPrefix ? pathPrefix + '/' : '', '')
			.replace(recordId, '')
			.replace(extension, '');
		let newFilename = (pathPrefix ? pathPrefix + '/' : '') + recordId + extension;
		if (curFileName === newFilename || version?.length) {
			if (version?.length) {
				version = (parseInt(version) || 0) + 1 + '';
			} else {
				version = '1';
			}

			newFilename = (pathPrefix ? pathPrefix + '/' : '') + recordId + version + extension;

			// await deleteFile(path + curFileName);
		}
		values[name] = newFilename;
		values[name + 'R2'] = newFilename;

		await uploadFile(
			path + values[name],
			fileObj,
			(progress) => {
				uploadProgressCallback(progress, newFilename);
			},
			true,
			values['title']?.en ?? values['title']?.hi ?? values['title'] ?? pathPrefix
		);
		delete values[name + '_FileObj'];
	}
};

export const dateTimeStr = (dateTime) => {
	let d = new AnandDate();
	d.setEpoch(dateTime.seconds * 1000);
	if (d.year() === 9999) {
		return 'Never';
	}
	return '[' + d.format('Do MMM, YYYY') + ']';
};

export const CustomDatePicker = (props: DatePickerProps) => {
	const {
		form: { setFieldValue },
		field: { name },
	} = props;

	const onAccept = React.useCallback(
		(date: Moment | null) => {
			if (date) {
				setFieldValue(name, date);
			}
		},
		[setFieldValue, name]
	);

	return <DatePicker {...fieldToDatePicker(props)} onAccept={onAccept} label={props.label ?? ''} />;

	// <Field
	// 	component={DatePicker}
	// 	format='DD/MM/YYYY'
	// 	name='creationDatePart'
	// 	label='Display Date'
	// 	autoOk
	// 	sx={{ width: '49%' }}
	// 	maxDate={values.publishDatePart}
	// />;
};
