import AnandDate from 'helpers/AnandDate';
import { createSelector } from 'reselect';
import { ApplicationState, TimerLog, TimerLogs } from 'types';

const getTimerLogs = (state: ApplicationState) => state.offlineState.timerLogs;
const getToday = (state: ApplicationState) => state.tempState.today;
const getDaySchedule = (state: ApplicationState) => state.uxState.dhyanDaySchedule;
const getStartDate = (state: ApplicationState) => state.uxState.dhyanStartDate;

export const getFilteredTimerLogs = createSelector([getTimerLogs], (timerLogs) => {
	let result = { ...timerLogs };

	for (let key in timerLogs) {
		if (timerLogs[key].duration <= 0) {
			delete result[key];
		}
	}

	// console.log(Object.keys(timerLogs).length, Object.keys(result).length);
	return result;
});

const splitTimerLogAtHour = (startTime: string, timerLog: TimerLog): TimerLogs => {
	let st = parseInt(startTime);

	let date = new AnandDate().setEpoch(st);

	let duration = timerLog.duration;
	let nextHourDate = new AnandDate().setEpoch(st).setHours(date.hours(), 0, 0).add(1, 'hour');
	let nextHrTime = nextHourDate.getDateObj().getTime();

	let timerLogs: TimerLogs = {};
	while (nextHrTime - st < duration * 1000) {
		let durationDiff = Math.round(nextHrTime / 1000) - Math.round(st / 1000);

		let partialTimerLog: TimerLog = {
			...timerLog,
			duration: durationDiff,
			endTime: nextHrTime,
		};

		timerLogs[st] = partialTimerLog;

		duration -= durationDiff;
		st = nextHrTime;
		nextHrTime += 3600 * 1000;
	}

	if (duration > 0) {
		let partialTimerLog: TimerLog = {
			...timerLog,
			duration: duration,
		};

		timerLogs[st] = partialTimerLog;
	}

	return timerLogs;
};

export const getTimerLogsByDate = createSelector([getFilteredTimerLogs, getToday, getDaySchedule], (timerLogs, today, daySchedule) => {
	let result: { [date: string]: { [startTime: string]: TimerLog } } = {};

	let maxDate = new AnandDate().setEpoch(today.getDateObj().getTime() - daySchedule * 1000);
	let minDate = new AnandDate(); //.subtract(410, 'day');

	for (let startTime in timerLogs) {
		let timerLog = timerLogs[startTime];

		let splitTimerLogs = splitTimerLogAtHour(parseInt(startTime) - daySchedule * 1000 + '', timerLog);
		for (let hourTime in splitTimerLogs) {
			let st = parseInt(hourTime);

			let date = new AnandDate().setEpoch(st);
			let dateStr = date.format('YYYY-MM-DD');

			result[dateStr] = result[dateStr] || {};
			result[dateStr][st] = splitTimerLogs[hourTime];

			if (date.isBefore(minDate)) {
				minDate = new AnandDate(dateStr);
			}
		}
	}

	for (let date = minDate.setHours(0, 0, 0); date.isSameOrBefore(maxDate); date.add(1, 'day')) {
		let dateStr = date.format('YYYY-MM-DD');
		if (result[dateStr] === undefined) {
			result[dateStr] = {};
		}
	}

	return result;
});

export const getTimerDurationByDate = createSelector([getTimerLogsByDate, getStartDate], (timerLogs, startDate) => {
	let result: { [day: string]: number } = {};

	for (let date in timerLogs) {
		if (new Date(date).getTime() < startDate * 1000) {
			continue;
		}

		let timerLogEntries = timerLogs[date];
		let dateStr = new AnandDate(date).format('YYYY-MM-DD');

		result[dateStr] = Object.values(timerLogEntries).reduce((p, c) => p + c.duration, 0);
	}

	// for (let i = 0; i < 30; i += 2) {
	// 	let today = new Date();
	// 	let date = new AnandDate().setDateObj(new Date(today.setDate(today.getDate() + -1 * i)));
	// 	let dateStr = date.format('YYYY-MM-DD');

	// 	result[dateStr] = Math.random() * 130 * 60;
	// }

	return result;
});

export const getTimerDailyAvg = createSelector([getTimerDurationByDate], (durationByDate) => {
	let durations = Object.values(durationByDate);
	let result = durations.reduce((prev, cur, i, arr) => {
		return prev + cur;
	}, 0);

	return result / durations.length;
});

export const getDurationsByHour = (timerLogsByDay, date) => {
	let result: { [hour: number]: number } = {};
	for (let i = 0; i < 24; i++) {
		result[i] = 0; //Math.random() * 600;
	}

	let timerLogs = timerLogsByDay[date] ?? {};

	for (let startTime in timerLogs) {
		let st = parseInt(startTime);
		let timerLog = timerLogs[st];
		let hour = new Date(st).getHours();
		result[hour] += timerLog.duration || 0;
	}

	return result;
};
