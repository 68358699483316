import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { Box, CssBaseline, IconButton, LinearProgress, Theme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import App from 'AppInterface/App';
import { AnnouncementControl } from 'components/notifier/AnnouncementControl';
import { AnnouncementSnackbar } from 'components/notifier/AnnouncementSnackbar';
import { Notifier } from 'components/notifier/Notifier';
import AudioPlayback from 'components/player/AudioPlayback';
import MediaPlayer from 'components/player/MediaPlayer';
import StatefulThemeProvider from 'components/providers/StatefulThemeProvider';
import AlertDialog from 'components/shell/AlertDialog';
import AppBar from 'components/shell/AppBar';
import BottomDrawer from 'components/shell/BottomDrawer';
import OnDemandPage from 'components/shell/OnDemandPage';
import PageRouter from 'components/shell/PageRouter';
import ProgressBar from 'components/shell/ProgressBar';
import { logEvent } from 'components/telemetry';
import { IsMinimalRhymeSite } from 'config';
import { Icons } from 'config/icons';
import { User } from 'firebase/auth';
import 'fonts/surekhbold-new.woff2';
import 'fonts/surekhnormal-new.woff2';
import { getBuildVersion, isAdmin, isAdminOrTest, isFakeWebViewEnv, isMobile, timeout } from 'helpers';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'scss/anand.content.scss';
import 'scss/anand.scss';
import { ArticlesFireStoreModule } from 'services/api/articles';
import { BookletsFireStoreModule } from 'services/api/booklets';
import { ConfigsFireStoreModule } from 'services/api/configs';
import { FeaturesFireStoreModule } from 'services/api/features';
import { HDFireStoreModule } from 'services/api/hd';
import { NotificationsFireStoreModule } from 'services/api/notifications';
import { EditionsFireStoreModule, PublicationsFireStoreModule } from 'services/api/publications';
import { QuotesFireStoreModule } from 'services/api/quotes';
import { RhymesFireStoreModule } from 'services/api/rhymes';
import { basename, getLocalState } from 'store/reducers';
import { enqueueSnackbar, initializeFilteredData, webAppInitialized } from 'store/temp/actions';
import { updateAppParams } from 'store/ux/actions';
import { makeStyles } from 'tss-react/mui';
import { ApplicationState, AppParams, Article, Edition, UserData } from 'types';
import './App.css';
import { appInterface, modulesInit, persistor, store } from './store';
import { history } from './store/history';

export const muiCache = createCache({
	key: 'mui',
	prepend: true,
});

// try {

// } catch (err) {
// 	fetch('/log-error', {
// 		method: 'post',
// 		headers: {
// 			'Content-type': 'application/json'
// 		},
// 		body: JSON.stringify({
// 			errObj: JSON.stringify({
// 				error: err,
// 				userAgent: navigator.userAgent
// 			})
// 		})
// 	});
// }

class ErrorBoundary extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: error };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log('ErrorBoundary', error, errorInfo);
		let stack = {};
		errorInfo?.componentStack
			?.split('\n')
			.map((line) => line.substr(0, 100))
			.forEach((line, index) => (index ? (stack['stack' + index] = line) : null));

		logEvent('JSErrorBoundary', { errorStr: error?.toString()?.substr(0, 90), ...stack });
		// setTimeout(() => window.location.href = "/", 1000);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div>
					<h4>Something went wrong.</h4>
					<p>{typeof this.state.error === 'object' ? JSON.stringify(this.state.error) : this.state.error}</p>
				</div>
			);
		}

		return this.props.children;
	}
}

window.onerror = function (msg, url, line, columnNo, error) {
	if (msg === 'ResizeObserver loop limit exceeded') {
		return false;
	}

	console.log('OnError: ', msg, url, line, columnNo, error?.stack);

	let stack = {};
	error?.stack
		?.split('\n')
		.map((line) => line.substr(0, 100))
		.forEach((line, index) => (index ? (stack['stack' + index] = line) : null));

	logEvent('JSOnError', { message: msg.toString().substr(0, 90), url: url, line: line, columnNo, ...stack });
	return false;
};

window.addEventListener('error', function (e) {
	if (e.message === 'ResizeObserver loop limit exceeded' || e.error?.message === 'ResizeObserver loop limit exceeded') {
		return false;
	}

	let error = e.error;
	console.log('Error occurred: ', error?.message, error?.stack);

	let stack = {};
	error?.stack
		?.split('\n')
		.map((line) => line.substr(0, 100))
		.forEach((line, index) => (index ? (stack['stack' + index] = line) : null));

	logEvent('JSErrorEvent', {
		message: (error?.message ?? e.message).substr(0, 90),
		line: e.lineno,
		col: e.colno,
		file: e.filename,
		...stack,
	});
	return false;
});

const classes = {
	root: {
		height: '100%',
	},
};

interface WebAppComponentProps {
	userData?: UserData;
	user?: User;
	updateAppParams: (appParams: AppParams) => void;
	isAdminTest: boolean;

	webAppInitialized: () => void;
	articles: any;
	filterInit: () => void;
	enqueueSnackbar: (notification: any) => void;
}

class WebAppComponent extends React.Component<WebAppComponentProps, { init: boolean }> {
	constructor(props) {
		super(props);

		// setTimeout(this.props.filterInit, 0);

		this.state = {
			init: false,
		};

		this.props.filterInit();
	}

	componentDidUpdate() {
		if (!isAdmin(this.props.user, this.props.userData)) {
			let style = document.createElement('style');
			style.textContent = '* { -webkit-user-select: none; }';
			window.document.head?.appendChild(style);
		} else {
			let style = document.createElement('style');
			style.textContent = '* { -webkit-user-select: auto; }';
			window.document.head?.appendChild(style);
		}
	}

	shouldComponentUpdate = (nextProps, nextState, nextContext) => {
		return this.state.init !== nextState.init || isAdmin(this.props.user, this.props.userData) !== isAdmin(nextProps.user, nextProps.userData);
	};

	componentDidMount = async () => {
		if (IsMinimalRhymeSite) {
			document.title = 'Rhymes Management';
		}

		let webappBuildVersion = getBuildVersion();

		console.log('Build Version: ' + webappBuildVersion);

		let configInit = () => modulesInit.initModules([new ConfigsFireStoreModule()], false);
		let allInit = () =>
			IsMinimalRhymeSite
				? modulesInit.initModules([new RhymesFireStoreModule()], false)
				: isFakeWebViewEnv() || this.props.isAdminTest
				? modulesInit.initModules(
						[
							new ArticlesFireStoreModule(),
							new PublicationsFireStoreModule(),
							new EditionsFireStoreModule(),
							new BookletsFireStoreModule(),
							new RhymesFireStoreModule(),
							new NotificationsFireStoreModule(),
							new QuotesFireStoreModule(),
							new HDFireStoreModule(),
							new FeaturesFireStoreModule(),
						],
						true
				  )
				: modulesInit.initModules(
						[
							new ArticlesFireStoreModule(true),
							new PublicationsFireStoreModule(),
							new EditionsFireStoreModule(),
							new BookletsFireStoreModule(),
							// new RhymesFireStoreModule(),
							new NotificationsFireStoreModule(),
							new QuotesFireStoreModule(),
							new HDFireStoreModule(),
							// new FeaturesFireStoreModule(),
						],
						true
				  );

		await appInterface.initialize();
		let appId = await App.getAppInstallationId();
		let appEnv = App.getAppEnv();
		let appVersion = App.getAppVersion();
		let appBuildCode = await App.getBuildVersionCode();

		this.props.updateAppParams({ appId, appEnv, appVersion, appBuildCode, webappBuildVersion });

		this.setState(
			{
				init: true,
			},
			async () => {
				modulesInit.initTrackerModule();
				await timeout(100);
				App.initialize(webappBuildVersion);
				console.log('App.initialize');
				this.props.webAppInitialized();

				// await uploadMediaFileToR2();
				// let articleInit = () => modulesInit.initModules([new ArticlesFireStoreModule()], false);
				// await articleInit();
				// await timeout(5000);

				// let editions: { [id: string]: Edition } = this.props.articles;
				// let tasks: Promise<unknown>[] = [];
				// let count = Object.keys(editions).length;
				// console.log('Total To Be Uploaded', count);
				// count = 0;
				// for (let key in editions) {
				// 	let edition = editions[key];
				// 	try {
				// 		console.log('Going to Upload to R2', edition);
				// 		tasks.push(uploadMediaFileToR2(edition));
				// 		console.log('Pushed for Uploaded', ++count);

				// 		// break;

				// 		if (tasks.length >= 500) {
				// 			await Promise.all(tasks);
				// 			tasks = [];
				// 			await timeout(1000);
				// 		}
				// 	} catch (error) {
				// 		console.log(error);
				// 		await timeout(3000);
				// 	}
				// }
			}
		);

		if (!isAdmin(this.props.user, this.props.userData)) {
			let style = document.createElement('style');
			style.textContent = '* { -webkit-user-select: none; }';
			window.document.head?.appendChild(style);
		}
	};

	render() {
		let { userData, user } = this.props;

		let init = this.state.init;
		if (init === false) {
			return (
				<Box sx={classes.root}>
					{isMobile() || isAdmin(user, userData) ? <AppBar /> : null}
					{isMobile() || isAdmin(user, userData) ? <ProgressBar /> : null}
				</Box>
			);
		}

		return (
			<Box sx={classes.root}>
				<CssBaseline />
				{isMobile() || isAdmin(user, userData) ? <AppBar /> : null}
				<PageRouter />
				<AudioPlayback />
				<BottomDrawer />
				<AlertDialog />
				<OnDemandPage />
				<MediaPlayer />
				<AnnouncementControl />
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
		userData: state.userState.userStore.userData,
		articles: state.dataState.editions.byId, //Object.keys(state.dataState.publications.byId),
		isAdminTest: isAdminOrTest(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateAppParams: (appParams: AppParams) => {
			dispatch(updateAppParams(appParams));
		},
		webAppInitialized: () => {
			dispatch(webAppInitialized());
		},
		filterInit: () => {
			dispatch(initializeFilteredData());
		},
		enqueueSnackbar: (notification: any) => {
			dispatch(enqueueSnackbar(notification));
		},
	};
}

const WebApp = connect(mapStateToProps, mapDispatchToProps)(WebAppComponent);

// add action to all snackbars
export const notistackRef: React.RefObject<any> = React.createRef();
const onClickDismiss = (key) => () => {
	notistackRef.current.closeSnackbar(key);
};

const useStyles = makeStyles()((theme: Theme) => ({
	containerRoot: {
		bottom: '66px; bottom: calc(66px + env(safe-area-inset-bottom)/2)',

		'&:has(div[variant="announcement"])': {
			zIndex: 1198,
		},

		'& .notistack-Snackbar': {
			minWidth: 'auto',
		},

		'&:has(.mini-announcement)': {
			bottom: '55px; bottom: calc(55px + env(safe-area-inset-bottom)/2)',
			maxWidth: '100%',

			'& .notistack-CollapseWrapper': {
				padding: 0,
			},
		},
	},
	root: {
		'& #notistack-snackbar': {
			fontSize: '1.5rem',
			flex: 1,
		},

		'& .notistack-MuiContent-success': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

const checkStateDiff = async () => {
	let storedState = (await getLocalState('ApplicationStateRoot')) as ApplicationState;
	let propsState = store.getState() as ApplicationState;

	// console.log('CheckStateDiff: StoredState - ', storedState, 'PropsState - ', propsState);

	let storedOpsState = { ...storedState?.opsState };
	let propsOpsState = { ...propsState?.opsState?.byId };
	delete storedOpsState['_persist'];
	delete propsOpsState['_persist'];

	let storedConfigState = { ...storedState?.dataState?.configs };
	let propsConfigState = { ...propsState?.dataState?.configs?.byId };
	delete storedConfigState['_persist'];
	delete propsConfigState['_persist'];

	let opsDiff = Object.keys(storedOpsState).length - Object.keys(propsOpsState).length;
	let configsDiff = Object.keys(storedConfigState).length - Object.keys(propsConfigState).length;

	// console.log('CheckStateDiff: OpsDiff - ', opsDiff, 'ConfigsDiff - ', configsDiff);
	if (opsDiff > 0 || configsDiff > 0) {
		await timeout(1000);
		await checkStateDiff();
	} else {
		return;
	}
};

const RootApp = () => {
	let { classes } = useStyles();

	return (
		<>
			<Provider store={store}>
				<PersistGate
					onBeforeLift={async () => {
						// console.log('PersistGate: onBeforeLift: State - ', { ...store.getState() });
						await checkStateDiff();
						// store.dispatch(
						// 	onEvent(new Context(), EventType.Information, 'PersistGate', {
						// 		success: true,
						// 		message: 'State Rehydrated!',
						// 	})
						// );
					}}
					persistor={persistor}
					loading={<LinearProgress />}
				>
					<ReduxRouter history={history} basename={basename}>
						<CacheProvider value={muiCache}>
							<StatefulThemeProvider>
								<ErrorBoundary>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<SnackbarProvider
											ref={notistackRef}
											// Components={{
											// 	success: StyledMaterialDesignContent,
											// 	error: StyledMaterialDesignContent,
											// 	warning: StyledMaterialDesignContent,
											// 	default: StyledMaterialDesignContent,
											// 	info: StyledMaterialDesignContent,
											// }}
											Components={{
												announcement: AnnouncementSnackbar,
											}}
											classes={{
												containerRoot: classes.containerRoot,
												root: classes.root,
											}}
											maxSnack={3}
											preventDuplicate={true}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											autoHideDuration={6000}
											disableWindowBlurListener={true}
											action={(key) => (
												<IconButton style={{ color: 'white' }} onClick={onClickDismiss(key)} size="large" className="snackbar-close">
													{Icons.Close}
												</IconButton>
											)}
										>
											<Notifier />
											<WebApp />
										</SnackbarProvider>
									</LocalizationProvider>
								</ErrorBoundary>
							</StatefulThemeProvider>
						</CacheProvider>
					</ReduxRouter>
				</PersistGate>
			</Provider>
		</>
	);
};

export default RootApp;
