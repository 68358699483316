import { Avatar, Badge, Box, Divider, FormControlLabel, InputBase, Switch } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { lime } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { alpha, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { logEvent } from 'components/telemetry';
import { IsMinimalRhymeSite } from 'config';
import { Icons } from 'config/icons';
import { adminRoute, bhajanRoute, satsangRoute } from 'config/route';
import { User } from 'firebase/auth';
import { getUserLocalPhoto, getWebAppAssetPath, isAdmin, isMobile } from 'helpers';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { history } from 'store/history';
import { openDrawer, searchFor, showAdminPreview } from 'store/temp/actions';
import { ApplicationState, Cart, UserData } from 'types';
import AppBarTitle from './AppBarTitle';
import AppDrawer from './AppDrawer';
import { withRouter } from './WithRouter';

const classes = {
	menuButton: {
		marginRight: 1,
	},
	title: {
		flexGrow: 1,
	},
	search: (theme: Theme) => ({
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		// marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: '3px',
			width: 'auto',
		},
	}),
	searchIcon: {
		width: 7,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	searchClose: {
		color: 'white',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: (theme: Theme) => ({
		padding: 1,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	}),
	sectionDesktop: (theme: Theme) => ({
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	}),
	sectionMobile: (theme: Theme) => ({
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	}),
	root: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	compRoot: {
		flexGrow: 1,
		display: 'block',

		'&.fullscreen': {
			display: 'none',
		},
	},
	input: {
		flex: 1,
		color: 'white',
		caretColor: '#fff',
	},
	iconButton: {
		color: 'white',
	},
	divider: {
		height: 28,
		margin: 0.5,
		backgroundColor: 'white',
	},
	userpic: {
		width: 24,
		height: 24,
	},
	badge: {
		'& .MuiBadge-badge': {
			backgroundColor: 'rgb(35 86 70)',
		},
	},
};

interface AppBarProps {
	onClickMenu: () => void;
	isAdmin: boolean;
	searchFor: (term: string) => void;
	history: any;
	user?: User;
	userData?: UserData;
	showPreview: boolean;
	cart: Cart;
	showAdminPreview: (value: boolean) => void;
	notificationCount: number;
	isFullScreen: boolean;
}

const PurpleSwitch = styled(Switch)(({ classes }) => ({
	'& .MuiSwitch-switchBase': {
		// color: grey[300],
		[`&.Mui-checked`]: {
			color: lime['A400'],
		},
		[`&.Mui-checked + .MuiSwitch-track`]: {
			backgroundColor: lime[900],
		},
	},
	checked: {},
	track: {},
}));

function PrimarySearchAppBar(props: AppBarProps) {
	const [showSearchBar, setShowSearchBar] = React.useState<Boolean>(false);
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [photoLocalUrl, setPhotoLocalUrl] = React.useState<string | undefined>(undefined);

	let { location, navigate } = props.history;

	useEffect(() => {
		if (props.user) {
			let photoUrl =
				(props.userData?.photoUrl && props.userData?.photoUrl.length > 1 ? props.userData?.photoUrl : undefined) ||
				props.user?.photoURL ||
				getWebAppAssetPath('app_icon.png');

			getUserLocalPhoto(props.user.uid, photoUrl).then((dataUrl) => {
				setPhotoLocalUrl(dataUrl || undefined);
			});
		}

		const unlisten = history.listen((update) => {
			props.searchFor('');
			setSearchTerm('');
			setShowSearchBar(false);
		});

		return () => {
			unlisten();
		};
	});

	let hiddenFor = ['phonesignin'];

	if (
		hiddenFor.indexOf(location.pathname.split('/')[1]) > -1 ||
		location.search.indexOf('noappbar') > -1
		//  || !props.user ||
		// !props.user.phoneNumber
	) {
		return null;
	}

	const onClickAccountBtn = () => {
		navigate('/account', {
			state: {
				isModal: true,
			},
		});
	};

	const onClickSearchBtn = (event: React.MouseEvent<HTMLElement>) => {
		setShowSearchBar(true);
	};

	const onCloseSearchBar = () => {
		props.searchFor('');
		setSearchTerm('');
		setShowSearchBar(false);
	};

	const onClickMenu = () => {
		onCloseSearchBar();
		props.onClickMenu();
	};

	let allowSearch = !!location.state?.allowSearch ?? false;

	let searchAllowed = ['/admin/articles', '/admin/rhymes', '/admin/quotes', '/admin/srcm', '/bhajan', '/satsang', '/shriamarvani', '/rhymes', '/sandesh'];
	allowSearch = allowSearch || searchAllowed.indexOf(location.pathname) >= 0;

	const match =
		matchPath(
			{
				path: bhajanRoute.to,
				caseSensitive: true,
				end: false,
			},
			location.pathname
		) ||
		matchPath(
			{
				path: satsangRoute.to,
				caseSensitive: true,
				end: false,
			},
			location.pathname
		);

	allowSearch = allowSearch || !!match;

	return (
		<Box sx={classes.compRoot} className={props.isFullScreen ? 'fullscreen' : ''}>
			<AppBar position="fixed">
				<Toolbar>
					<IconButton onClick={onClickMenu} edge="start" sx={classes.menuButton} color="inherit" size="large">
						{Icons.Menu}
					</IconButton>

					{showSearchBar && (
						<Box
							component="form"
							onSubmit={(event) => {
								event.preventDefault();
								event.stopPropagation();

								props.searchFor(searchTerm);
							}}
							sx={classes.root}
						>
							<InputBase
								value={searchTerm}
								autoFocus
								onChange={(event) => {
									setSearchTerm(event.target.value);
									props.searchFor(event.target.value);
								}}
								sx={classes.input}
								placeholder="Search"
							/>
							<IconButton type="submit" sx={classes.iconButton} size="large">
								{Icons.Search}
							</IconButton>
							<Divider sx={classes.divider} orientation="vertical" />
							<IconButton onClick={onCloseSearchBar} sx={classes.iconButton} size="large">
								{Icons.Close}
							</IconButton>
						</Box>
					)}

					{!showSearchBar && (
						<>
							<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
								<AppBarTitle />
								{/* <Box sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{props.user?.phoneNumber ? props.user?.phoneNumber : props.user?.email ? props.user?.email : ''}
								</Box> */}
							</Box>

							{allowSearch && (
								<IconButton onClick={onClickSearchBtn} color="inherit" size="large">
									{Icons.Search}
								</IconButton>
							)}

							{props.isAdmin && !isMobile() ? (
								<>
									{matchPath({ path: adminRoute.to, caseSensitive: true }, location.pathname) && (
										<FormControlLabel
											control={
												<PurpleSwitch
													checked={props.showPreview}
													onChange={() => {
														props.showAdminPreview(!props.showPreview);
													}}
												/>
											}
											label={location.pathname.endsWith('srcm') ? 'Meaning' : 'Preview'}
										/>
									)}
									{IsMinimalRhymeSite ? null : (
										<>
											<IconButton
												onClick={() =>
													navigate('/dailyquote', {
														state: {
															isModal: true,
														},
													})
												}
												color="inherit"
												size="large"
											>
												{Icons.Quote}
											</IconButton>
											<IconButton
												onClick={() =>
													navigate('/notices', {
														state: {
															isModal: true,
														},
													})
												}
												color="inherit"
												size="large"
											>
												{Icons.Notification}
											</IconButton>
										</>
									)}
								</>
							) : null}
							{isMobile() && !IsMinimalRhymeSite ? (
								<IconButton
									onClick={() =>
										navigate('/notices', {
											state: {
												isModal: true,
											},
										})
									}
									color="inherit"
									size="large"
								>
									<Badge badgeContent={props.notificationCount} color="secondary">
										{Icons.Notification}
									</Badge>
								</IconButton>
							) : null}
							{Object.keys(props.cart.items).length > 0 && (
								<IconButton
									onClick={() => {
										navigate('/cart', { state: { isModal: true } });
									}}
									color="inherit"
									size="large"
								>
									<Badge sx={classes.badge} badgeContent={Object.keys(props.cart.items).length}>
										{Icons.Cart}
									</Badge>
								</IconButton>
							)}

							<IconButton onClick={onClickAccountBtn} color="inherit" size="large">
								{photoLocalUrl ? <Avatar src={photoLocalUrl} variant="circular" sx={classes.userpic} /> : Icons.Account}
							</IconButton>
						</>
					)}
				</Toolbar>
			</AppBar>
			<Toolbar />
			<AppDrawer />
		</Box>
	);
}

function mapStateToProps(state: ApplicationState) {
	let notifications = state.filteredDataState.notifications;
	let acks = state.uxState.acknowledge;
	let active = notifications.filter((notice) => !acks[notice.id] || acks[notice.id] < notice.updatedAt.seconds);

	return {
		isAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
		user: state.userState.userStore.user,
		userData: state.userState.userStore.userData,
		showPreview: state.tempState.showAdminPreview,
		cart: state.dataState.cart,
		notificationCount: active.length,
		isFullScreen: state.tempState.isFullScreen ?? false,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		onClickMenu: () => {
			dispatch(openDrawer());
		},
		searchFor: (term: string) => {
			if (term && term.length) {
				logEvent('search', term);
			}

			dispatch(searchFor(term));
		},
		showAdminPreview: (value: boolean) => {
			dispatch(showAdminPreview(value));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrimarySearchAppBar));
