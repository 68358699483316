import { Box, Card } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { User } from 'firebase/auth';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { signout, updateEmailAddress } from 'services/api/auth';
import { onEvent } from 'store/temp/actions';
import { ApplicationState } from 'types';
import { NewPhoneSignInControl } from './NewPhoneSignInControl';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
		flexGrow: 1,
	}),
	gsignin: (theme: Theme) => ({
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		border: '1px solid ' + theme.palette.primary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	}),
	or: {
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -9,
		alignSelf: 'center',
	},
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(2),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
	newuser: (theme: Theme) => ({
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	}),
};

export interface SignInProps {
	showMessage: (...payload) => void;
	signout: () => Promise<void>;
	user?: User;
}

class UpdateEmail extends React.Component<SignInProps, any> {
	context!: React.ContextType<typeof DialogContext>;

	render() {
		return (
			<Box sx={classes.root}>
				<UserPicInfo />
				<Card sx={classes.card}>
					<NewPhoneSignInControl
						setMode={(mode: string) => {
							this.setState({ mode });
						}}
						style={{ width: '90%', margin: 'auto' }}
						allowSkip={false}
					/>
				</Card>
			</Box>
		);
	}
}

function mapStateToProps({ userState }: ApplicationState) {
	let { userStore } = userState;

	return {
		user: userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		signout: async () => {
			await dispatch(signout({}, new Context()));
		},
	};
}

UpdateEmail.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);
