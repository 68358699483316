import { Box, Button, Card, LinearProgress, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { Icons } from 'config/icons';
import { User } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { signout, updateEmailAddress } from 'services/api/auth';
import { enqueueSnackbar, EventType, onEvent } from 'store/temp/actions';
import { ApplicationState } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	gsignin: (theme: Theme) => ({
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		border: '1px solid ' + theme.palette.primary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	}),
	or: {
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -9,
		alignSelf: 'center',
	},
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(2),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
	newuser: (theme: Theme) => ({
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	}),
};

export interface SignInProps {
	updateEmailAddress: (email: string, password?: string) => Promise<void>;
	showMessage: (...payload) => void;
	signout: () => Promise<void>;
	user?: User;
	enqueueSnackbar: (args) => void;
}

class UpdateEmail extends React.Component<SignInProps, any> {
	context!: React.ContextType<typeof DialogContext>;

	render() {
		let { showMessage, updateEmailAddress, signout, user, enqueueSnackbar } = this.props;

		return (
			<Box sx={classes.root}>
				<UserPicInfo />
				<Card sx={classes.card}>
					<Formik
						initialValues={{
							email: '',
						}}
						validationSchema={() =>
							Yup.object({
								email: Yup.string().email('Invalid email').required('Required'),
							})
						}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								await updateEmailAddress(values.email);
								this.context.popScreen();

								showMessage(new Context(), EventType.Information, 'signup', {
									success: true,
									message: 'Verification email sent to ' + values.email,
								});
								signout();
							} catch (error) {
								if (error.code === 'auth/too-many-requests') {
									showMessage(new Context(), EventType.Information, 'signin', {
										success: false,
										message: 'Too many requests! Please try after some time!',
									});
								} else if (error.code === 'auth/requires-recent-login') {
									showMessage(new Context(), EventType.Exception, 'signin', {
										success: false,
										message: 'Login again to update Email',
									});
									this.context.popScreen();
									signout();
								} else {
									enqueueSnackbar({
										message: error?.message ?? JSON.stringify(error),
										options: {
											variant: 'error',
										},
									});
								}
							}
							setSubmitting(false);
						}}
					>
						{({ submitForm, isSubmitting, setSubmitting, values }) => (
							<>
								{isSubmitting ? <LinearProgress /> : null}
								{user?.email && user?.emailVerified ? (
									<>
										<Icons.WarningIcon style={{ fontSize: 64, alignSelf: 'center', color: 'red' }} />
										<Typography sx={classes.message} variant="body2" component="div">
											<h4>Changing your email will keep all your data (i.e., playlists) intact.</h4>
											<h4>However, it will:</h4>
											<ul style={{ paddingLeft: 24 }}>
												<li>Require email verification for new email</li>
												<li>Require you to update your phone number again</li>
											</ul>
										</Typography>
									</>
								) : (
									<Typography sx={classes.message} variant="body2" component="div">
										<h4>Email address is required:</h4>
									</Typography>
								)}
								<Box component={Form} sx={classes.form} translate="yes">
									<Field
										component={TextField}
										name="email"
										type="email"
										label="Email Address"
										autoComplete="off"
										autoCapitalize="off"
										autoCorrect="off"
										spellCheck="false"
										value={values.email}
									/>

									<Button sx={classes.signinbtn} variant="contained" onClick={submitForm} disabled={isSubmitting}>
										Confirm
									</Button>
								</Box>
							</>
						)}
					</Formik>
				</Card>
			</Box>
		);
	}
}

function mapStateToProps({ userState }: ApplicationState) {
	let { userStore } = userState;

	return {
		user: userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateEmailAddress: async (email: string, password?: string) => {
			await dispatch(updateEmailAddress(email, password));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		signout: async () => {
			await dispatch(signout({}, new Context()));
		},
		enqueueSnackbar: (args) => {
			dispatch(enqueueSnackbar(args));
		},
	};
}

UpdateEmail.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);
