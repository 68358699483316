import { Avatar, Box, Button, Card, CardHeader, Divider, IconButton, Theme, Typography } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { DialogContext } from 'components/pages/DialogPage';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { withRouter } from 'components/shell/WithRouter';
import { IsMinimalRhymeSite, IsOwnerSAPD } from 'config';
import { Icons } from 'config/icons';
import { User } from 'firebase/auth';
import { isAdminOrTest, isMobile, isTest, isFakeWebViewEnv, isAdmin } from 'helpers';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { signout } from 'services/api/auth';
import { ApplicationState, UserData } from 'types';
import { AccountMode } from './AccountScreenManager';
import { SignOut } from './SignOut';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		paddingBottom: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
	}),
	options: (theme: Theme) => ({
		paddingTop: theme.spacing(3),

		'& .MuiCardHeader-subheader': {
			// fontSize: '1.3rem',
			letterSpacing: 0,
		},
	}),
	signoutbtn: (theme: Theme) => ({
		height: 42,
		width: '60%',
		alignSelf: 'center',
		marginTop: theme.spacing(4),
	}),
	divider: (theme: Theme) => ({
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	}),
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
};

export interface UserOptionsProps extends ScreenManagerProps {
	signout: () => Promise<void>;
	userData?: UserData;
	user?: User;
	initScreen?: string;
	history: any;
	isAdminOrTest: boolean;
}

class UserOptions extends React.Component<UserOptionsProps> {
	context!: React.ContextType<typeof DialogContext>;

	// private pushScreen(key) {
	// 	if (key === AccountMode.Feedback && !this.props.user && IsOwnerSAPD) {
	// 		this.context.pushScreen({
	// 			title: 'Sign In to Submit Feedback',
	// 			key: AccountMode.SignIn,
	// 		});

	// 		return;
	// 	}

	// 	this.context.pushScreen({
	// 		title: this.getScreenTitle(key),
	// 		key,
	// 		dark: key !== AccountMode.SignIn,
	// 	});
	// }

	// componentDidMount() {
	// 	if (this.props.initScreen) {
	// 		this.pushScreen(this.props.initScreen);
	// 	}
	// }

	render() {
		let { user, pushScreen, userData, isAdminOrTest } = this.props;

		return (
			<Box sx={classes.root}>
				{user ? (
					<UserPicInfo />
				) : IsOwnerSAPD ? (
					<Button
						sx={classes.signoutbtn}
						variant="contained"
						color="primary"
						onClick={() => pushScreen(isMobile() ? AccountMode.PhoneSignIn : AccountMode.SignIn)}
					>
						Sign In
					</Button>
				) : null}
				<Box sx={classes.options}>
					{user ? (
						<>
							<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Profile)}>
								<CardHeader
									avatar={<Avatar sx={classes.avatar}>{Icons.Person}</Avatar>}
									action={<IconButton>{Icons.NavigateNext}</IconButton>}
									title={<Typography>Account Details</Typography>}
									subheader="Update profile details"
								/>
							</Card>
							{/* {isTest(user, userData) ? ( */}
							<>
								{/* {!user.email ? (
									<Card sx={classes.card} onClick={() => pushScreen(AccountMode.UpdateEmail)}>
										<CardHeader
											avatar={<Avatar sx={classes.avatar}>{Icons.Email}</Avatar>}
											action={<IconButton>{Icons.NavigateNext}</IconButton>}
											title={<Typography>{user.email ? 'Update Email' : 'Add Email'}</Typography>}
											subheader={user.email ? 'Update email address' : 'Add email address'}
										/>
									</Card>
								) : null} */}
								{/* {!user.phoneNumber ? ( */}
								<Card sx={classes.card} onClick={() => pushScreen(AccountMode.UpdatePhone)}>
									<CardHeader
										avatar={<Avatar sx={classes.avatar}>{Icons.Phone}</Avatar>}
										action={<IconButton>{Icons.NavigateNext}</IconButton>}
										title={<Typography>{user.phoneNumber ? 'Change Phone' : 'Add Phone'}</Typography>}
										subheader={user.phoneNumber ? 'Change phone number' : 'Add phone number'}
									/>
								</Card>
								{/* ) : null} */}
							</>
							{/* ) : null} */}
							{/* {(isAdmin(user, userData) || userData?.email === 'digital.arya@gmail.com') && (
								<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Addresses)}>
									<CardHeader
										avatar={<Avatar sx={classes.avatar}>{Icons.Home}</Avatar>}
										action={<IconButton>{Icons.NavigateNext}</IconButton>}
										title={<Typography>Shipping Address</Typography>}
										subheader='Manage address details'
									/>
								</Card>
							)} */}
							{user.providerData.map((provider) => provider?.providerId).indexOf('password') >= 0 ? (
								<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Password)}>
									<CardHeader
										avatar={<Avatar sx={classes.avatar}>{Icons.Lock}</Avatar>}
										action={<IconButton>{Icons.NavigateNext}</IconButton>}
										title={<Typography>Password</Typography>}
										subheader="Change password"
									/>
								</Card>
							) : null}
							<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Delete)}>
								<CardHeader
									avatar={<Avatar sx={classes.avatar}>{Icons.Delete}</Avatar>}
									action={<IconButton>{Icons.NavigateNext}</IconButton>}
									title={<Typography>Delete</Typography>}
									subheader="Delete your account"
								/>
							</Card>
							<Divider sx={classes.divider}></Divider>
							{/* {(isAdmin(user, userData) || userData?.email === 'digital.arya@gmail.com') && (
								<>
									<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Orders)}>
										<CardHeader
											avatar={<Avatar sx={classes.avatar}>{Icons.Cart}</Avatar>}
											action={<IconButton>{Icons.NavigateNext}</IconButton>}
											title={<Typography>Publication Orders</Typography>}
											subheader='Your orders of publications'
										/>
									</Card>
									<Card
										sx={classes.card}
										onClick={() => pushScreen(AccountMode.Subscriptions)}
									>
										<CardHeader
											avatar={<Avatar sx={classes.avatar}>{Icons.Repeat}</Avatar>}
											action={<IconButton>{Icons.NavigateNext}</IconButton>}
											title={<Typography>Journal Subscriptions</Typography>}
											subheader='Manage your journal subscriptions'
										/>
									</Card>
									<Divider sx={classes.divider}></Divider>
								</>
							)} */}
						</>
					) : null}

					{!IsMinimalRhymeSite && user && IsOwnerSAPD && (isFakeWebViewEnv() || isAdmin(user, userData)) ? (
						<>
							<Card sx={classes.card} onClick={() => pushScreen(AccountMode.NityaNiyam)}>
								<CardHeader
									avatar={<Avatar sx={classes.avatar}>{Icons.Settings}</Avatar>}
									action={<IconButton>{Icons.NavigateNext}</IconButton>}
									title={<Typography>Nitya Niyam</Typography>}
									subheader="Nitya Niyam Settings"
								/>
							</Card>
							<Divider sx={classes.divider}></Divider>
						</>
					) : null}
					<Card sx={classes.card} onClick={() => pushScreen(AccountMode.ChangeLang)}>
						<CardHeader
							avatar={<Avatar sx={classes.avatar}>{Icons.Language}</Avatar>}
							action={<IconButton>{Icons.NavigateNext}</IconButton>}
							title={<Typography>Language</Typography>}
							subheader="Select English or Hindi"
						/>
					</Card>
					{!IsMinimalRhymeSite && (
						<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Settings)}>
							<CardHeader
								avatar={<Avatar sx={classes.avatar}>{Icons.Settings}</Avatar>}
								action={<IconButton>{Icons.NavigateNext}</IconButton>}
								title={<Typography>Settings</Typography>}
								subheader="Notifications & App settings"
							/>
						</Card>
					)}
					{/* <Card className={classes.card} onClick={() => {}}>
						<CardHeader
							className={classes.header}
							avatar={
								<Avatar className={classes.avatar}>
									<OfflinePinIcon />
								</Avatar>
							}
							action={
								<IconButton>
									<NavigateNextIcon />
								</IconButton>
							}
							title={<Typography>Offline Data</Typography>}
							subheader='View offline data stored on device'
						/>
					</Card> */}
					<Divider sx={classes.divider}></Divider>
					{/* <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Faq)}>
						<CardHeader
							className={classes.header}
							avatar={<Avatar className={classes.avatar}>{Icons.Help}</Avatar>}
							action={<IconButton>{Icons.NavigateNext}</IconButton>}
							title={<Typography>FAQ</Typography>}
							subheader='Frequently asked questions'
						/>
					</Card> */}
					{IsOwnerSAPD && !IsMinimalRhymeSite ? (
						<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Feedback)}>
							<CardHeader
								avatar={<Avatar sx={classes.avatar}>{Icons.Feedback}</Avatar>}
								action={<IconButton>{Icons.NavigateNext}</IconButton>}
								title={<Typography>Feedback</Typography>}
								subheader="Provide your feedback"
							/>
						</Card>
					) : null}
					{!IsMinimalRhymeSite && (
						<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Contact)}>
							<CardHeader
								avatar={<Avatar sx={classes.avatar}>{Icons.Business}</Avatar>}
								action={<IconButton>{Icons.NavigateNext}</IconButton>}
								title={<Typography>Contact Us</Typography>}
								subheader="Postal address"
							/>
						</Card>
					)}
					{!IsMinimalRhymeSite && (
						<Card sx={classes.card} onClick={() => pushScreen(AccountMode.Policy)}>
							<CardHeader
								avatar={<Avatar sx={classes.avatar}>{Icons.Policy}</Avatar>}
								action={<IconButton>{Icons.NavigateNext}</IconButton>}
								title={<Typography>Policies</Typography>}
								subheader="Terms and Privacy Policy"
							/>
						</Card>
					)}
				</Box>
				<SignOut />
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	let { userStore } = state.userState;

	return {
		userData: userStore.userData,
		user: userStore.user,
		isAdminOrTest: isAdminOrTest(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		signout: async () => {
			await dispatch(signout({}, new Context()));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserOptions));
