import { logEvent } from 'components/telemetry';
import { TimerAudio } from 'config';
import firebase, { fireAuth } from 'fb/initconfig';
import { getAnalytics, logEvent as fireLog, setUserProperties } from 'firebase/analytics';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFormattedTimeFromSec, isSafari, timeout } from 'helpers';
import { appInterface } from 'store';
import { IAnandApp } from './IAnandApp';

export class JSAnandApp implements IAnandApp {
	private version: number = -1;
	private AnandApp: any;
	private callbacks = {};
	public appInstallationId;
	public buildVersionCode;

	public initialize = (webappBuildVersion?) => {};

	public getAppVersion = () => {
		return this.version;
	};

	public receiveFromApp(opName, data) {}

	public getBuildVersionCode = async () => {
		return -1;
	};

	public downloadMedia = (id, filename, src, force) => {
		this.downloadFile(src, filename, id, true);
	};

	private downloadBlob(blob: Blob, filename: string) {
		var type = blob.type.split('/').pop();
		type = type === 'plain' ? 'txt' : type;

		let url = URL.createObjectURL(blob);

		var d = document.createElement('a');
		d.className = 'download';
		d.style.display = 'none';
		d.download = filename;
		d.href = url;
		document.body.appendChild(d);
		if (isSafari()) {
			d.target = '_blank';
		}
		d.click();
		d.parentElement?.removeChild(d);
		URL.revokeObjectURL(url);
	}

	public downloadFile = async (path: string, filename: string, id?: string, isMediaMock?: boolean) => {
		if (isMediaMock) {
			console.log(path, filename, id);

			let total = 10000;
			let step = 50;
			let increment = total / step;
			let time = 2000;
			let waitTime = time / step;
			await timeout(5000);

			for (let loaded = 0; loaded <= total; loaded += increment) {
				appInterface.didDownloadAudioData(id, 0, loaded, total);
				var percentComplete = (loaded * 100) / total;

				if (percentComplete >= 100) {
					appInterface.didDownloadMedia(id, true);
				}

				await timeout(waitTime + (Math.random() * waitTime) / 10);
			}

			return;
		}

		try {
			let response = await fetch(path);

			const contentLength = response.headers.get('content-length') || '0';
			const total = parseInt(contentLength, 10);
			const values: Uint8Array[] = [];
			let loaded = 0;

			const reader = response.body?.getReader();
			while (reader) {
				const { done, value } = await reader.read();
				if (done) break;
				values.push(value);
				loaded += value.byteLength;
				!!id && appInterface.didDownloadAudioData(id, 0, loaded, total);
			}

			const blob = new Blob(values);
			!!id && appInterface.didDownloadMedia(id, true);

			console.log('Blob Download Success: ', path);

			this.downloadBlob(blob, filename);
		} catch (err) {
			console.log('Download Failed: ', path);
			throw err;
		}
	};

	public getAppInstallationId = async () => {
		return 'Web';
	};

	public getAppDetailesJson = async () => {};

	public deleteMedia = (id, filename) => {};

	public loadGoogleSignin = async () => {
		try {
			let provider = new GoogleAuthProvider();
			provider.addScope('email');
			provider.addScope('profile');
			let result = await signInWithPopup(fireAuth, provider);
			appInterface.googleUserSignedIn(result);
		} catch (e) {
			appInterface.googleUserSignedIn(null, e);
		}
	};

	public gSignout = () => {};

	public loadAppleSignin = () => {};
	public appleSignout = () => {};

	public shareLink = (title, link, type, id) => {
		logEvent('share', { content_type: type, item_id: id, link, title });
		window.prompt('Copy to clipboard: Ctrl+C, Enter:: ' + title, link);
	};

	public shareImageDataURI = (title: string, dataURI: string, link: string) => {
		logEvent('share', { link, title });
		// window.prompt('Copy to clipboard: Ctrl+C, Enter:: ' + title, link);
		this.downloadFile(dataURI, title + '.jpg');
	};

	public getAppEnv = () => {
		return 'Web';
	};

	public showToast = (toast) => {
		console.log(toast);
	};

	public showProgress = () => {};

	public hideProgress = () => {};

	public showSnack = (message) => {
		console.log(message);
	};

	public putToAppStorage = (key, value) => {
		localStorage.setItem(key, value);
	};

	public removeFromAppStorage = (key) => {
		localStorage.removeItem(key);
	};

	public getFromAppStorage = async (key) => {
		return localStorage.getItem(key);
	};

	public subscribeToTopic = (topic) => {};

	public unsubscribeFromTopic = (topic) => {};

	public playMedia = (audioId, src, filename, title, isFav, playAuto, startTime, albumTitle, artist) => {
		new Audio(src).play();
	};

	public playAllMedia = (mediaArryaJson: string, replace: boolean) => {};

	public togglePlayPause = () => {};

	public downloadCurrentMedia = (title, force) => {};

	public deleteCurrentMedia = () => {};

	public stopAndRelease = () => {};

	public seekToTime = (time) => {};

	public setPlaybackSpeed = (speed) => {};

	public setRepeatMode = (repeatMode: number) => {};
	public setShuffle = async (shuffle: boolean) => {
		return [];
	};
	public playNextMedia = () => {};
	public playPrevMedia = () => {};
	public hasNextMedia = async () => {
		return false;
	};
	public hasPrevMedia = async () => {
		return false;
	};
	public getMediaIdAt = async (i: number) => {
		return '';
	};
	public getCurMediaId = async () => {
		return '';
	};
	public getMediaCount = async () => {
		return 0;
	};
	public getCurMediaIndex = async () => {
		return -1;
	};
	public seekToMediaItemIndex = (itemIndex: number, time: number) => {};

	public exportMedia = async (id, filename, src) => {
		return await this.downloadFile(src, filename, id);
	};

	public logEvent = (name, params) => {
		let analytics = getAnalytics(firebase);
		fireLog(analytics, name, params);
	};

	public setUserProperty = (name, value) => {
		let analytics = getAnalytics(firebase);
		setUserProperties(analytics, { [name]: value });
	};

	public clearAppData() {}

	public clearWebViewCache() {}

	private timer;
	public startCountDownTimer(timerId, forSeconds) {
		TimerAudio.pause();

		let startTime = new Date().getTime();
		let forMillis = forSeconds * 1000;
		let finishTime = startTime + forMillis;

		if (this.timer) {
			this.cancelCountDownTimer();
		}

		this.timer = setInterval(() => {
			let curTime = new Date().getTime();
			let millisUntilFinished = finishTime - curTime;
			let remainingSecs = Math.round(millisUntilFinished / 1000);

			appInterface.onTimerTick(timerId, remainingSecs, curTime);

			if (new Date().getTime() >= finishTime) {
				this.cancelCountDownTimer();
				appInterface.onTimerFinish();
				TimerAudio.play();
			}
		}, 1000);
	}

	public cancelCountDownTimer() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = undefined;
		}
	}

	public pauseCountDownTimer() {
		this.cancelCountDownTimer();
	}

	public resumeCountDownTimer(timerId, forSeconds, elapsedTime, withMusic) {
		this.startCountDownTimer(timerId, forSeconds);
	}

	public enableTimerMusic(enabled: boolean) {}

	public disableScreenshot = () => {};
	public enableScreenshot = () => {};

	public openUrlInBrowser = (url: string) => {
		var d = document.createElement('a');
		d.style.display = 'none';
		d.href = url;
		document.body.appendChild(d);
		d.target = '_blank';
		d.click();
		d.parentElement?.removeChild(d);
	};

	public setPlaybackSpeedPolicy = (playbackSpeedPolicy: string) => {};
}
