import { LinearProgress } from '@mui/material';
import { withRouter } from 'components/shell/WithRouter';
import { getContentStorageUrl } from 'helpers';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState, ContentType } from 'types';
import DialogPage from './DialogPage';
import HomePage from './HomePage';
const PDFViewerApp = React.lazy<any>(() => import('components/pdf/PDFViewer'));

function PublicationPage({ publications, editions, isModal }) {
	let { id } = useParams();

	if (!id || !editions.byId[id]) {
		return <HomePage />;
	}

	let edition = editions.byId[id!];
	let publication = publications.byId[edition.publicationId];

	let pdfUrl: string = getContentStorageUrl(ContentType.Edition, 'file', edition);
	let title = publication.title;

	return (
		<DialogPage disableBackdropClick title={title} dark>
			<Suspense fallback={<LinearProgress />}>
				<PDFViewerApp pdfUrl={pdfUrl} />
			</Suspense>
		</DialogPage>
	);
}

function mapStateToProps(state: ApplicationState) {
	let editions = state.dataState.editions;
	let publications = state.dataState.publications;

	return {
		editions,
		publications,
	};
}

export default connect(mapStateToProps)(withRouter(PublicationPage));
