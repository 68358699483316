import { Box, darken, IconButton, Typography } from '@mui/material';
import { PrimaryMainColor } from 'config';
import { Icons } from 'config/icons';
import { PrimaryMainColorDark } from 'config/theme';
import { getFormattedTimeFromSec } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getTimerDailyAvg, getTimerDurationByDate, getDurationsByHour, getTimerLogsByDate } from 'store/data/timer/selectors';
import { ApplicationState, TimerRunningState } from 'types';
import { TimerPresetControl } from './TimerPresetControl';

interface TimerAnalyticsProps {
	durationsByDate: { [date: string]: number };
	dailyAvg: number;
	isCustomMode: boolean;
	goal: number;
	onDateClick: (date: string) => void;
	sx?: any;
}

const timerThemeColor = '#fd9900';
const timerThemeColorDark = '#da8503';
const successColor = PrimaryMainColor; //'#038b00c7'; // PrimaryMainColor;
const successDarkColor = darken(PrimaryMainColor, 0.3); //'#038b00c7'; // PrimaryMainColor;

const getPeriodStr = (minDateStr: string, maxDateStr: string) => {
	// if (minDateStr === maxDateStr) {
	// 	return '';
	// }

	let minDateObj = new AnandDate(minDateStr);
	let maxDateObj = new AnandDate(maxDateStr);

	let minDate = minDateObj.date();
	let maxDate = maxDateObj.date();

	let minMonth = minDateObj.month();
	let maxMonth = maxDateObj.month();

	let minYear = minDateObj.year();
	let maxYear = maxDateObj.year();

	let periodStr = maxDateObj.format(" — 0DD MMM'YY");

	if (minYear !== maxYear) {
		periodStr = minDateObj.format("0DD MMM'YY") + periodStr;
	} else {
		if (minMonth !== maxMonth) {
			periodStr = minDateObj.format('0DD MMM') + periodStr;
		} else {
			periodStr = minDateObj.format('0DD') + periodStr;
		}
	}

	return periodStr;
};

enum Mode {
	All,
	Year,
	Semester,
	Month,
	Week,
	Day,
	Hour,
}

const classes = {
	topMsgRoot: {
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	topMsgLeftSection: {
		alignSelf: 'center',
		// display: 'flex',
		// flexDirection: 'column',
		// flexGrow: 1,
		// '& > .MuiTypography-root': {
		// 	lineHeight: 1.2,
		// },
	},
	topMsgRightSection: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		textAlign: 'right',

		'& > .MuiTypography-root': {
			lineHeight: 1.2,
		},
	},
	allDaysWrap: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 0.75,
		flexGrow: 1,
		overflow: 'auto',
		// direction: 'rtl',
	},
};

const AllKPIMsg = (props) => {
	let { durationsByDate, dailyAvg, goal } = props;
	let sortedDates = Object.keys(durationsByDate).sort((a, b) => (a > b ? -1 : 1));
	let periodStr = getPeriodStr(sortedDates[sortedDates.length - 1], sortedDates[0]);

	return (
		<Box sx={classes.topMsgRoot}>
			<Box sx={classes.topMsgLeftSection}></Box>
			<Box sx={classes.topMsgRightSection}>
				<Typography variant="subtitle2" color="textSecondary" fontSize={'1.2rem'}>
					AVERAGE
				</Typography>
				<Typography variant="h6" color={dailyAvg >= goal ? successColor : 'textPrimary'}>
					{dailyAvg > 0 ? getFormattedTimeFromSec(dailyAvg, true) : '0 min'}
				</Typography>
				<Typography variant="subtitle2" color="textSecondary" fontSize={'1.2rem'}>
					{periodStr}
				</Typography>
			</Box>
		</Box>
	);
};

const DayKPIMsg = (props) => {
	let { duration, date, hour } = props;
	let periodStr = hour !== undefined ? new AnandDate(date).setHours(hour, 0, 0).format("-hhA, DD MMM'YY") : new AnandDate(date).format("dd, DD MMM'YY");

	return (
		<Box sx={classes.topMsgRoot}>
			<Box sx={classes.topMsgLeftSection}></Box>
			<Box sx={classes.topMsgRightSection}>
				<Typography variant="subtitle2" color="textSecondary" fontSize={'1.2rem'}>
					TOTAL
				</Typography>
				<Typography variant="subtitle1" color="textPrimary">
					{getFormattedTimeFromSec(duration, false)}
				</Typography>
				<Typography variant="subtitle2" color="textSecondary" fontSize={'1.2rem'}>
					{periodStr}
				</Typography>
			</Box>
		</Box>
	);
};

const _KPIMsg = (props) => {
	let { mode, durationsByDate, dailyAvg, duration, goal } = props;

	let KPIMsg = <></>;
	switch (mode.mode) {
		case Mode.All:
			KPIMsg = <AllKPIMsg durationsByDate={durationsByDate} dailyAvg={dailyAvg} goal={goal} />;
			break;
		case Mode.Day:
			KPIMsg = <DayKPIMsg duration={duration} date={mode.date} />;
			break;
		case Mode.Hour:
			KPIMsg = <DayKPIMsg duration={duration} date={mode.date} hour={mode.hour} />;
			break;
	}

	return KPIMsg;
};

const KPIMsg = connect((state: ApplicationState, props: any) => {
	let durationsByDate = getTimerDurationByDate(state);
	let dailyAvg = getTimerDailyAvg(state);
	dailyAvg = Math.floor(dailyAvg / 60) * 60;

	let timerLogsByDate = getTimerLogsByDate(state);
	let today = new AnandDate().setEpoch(state.tempState.today.getDateObj().getTime() - state.uxState.dhyanDaySchedule * 1000);

	let timerState = state.tempState.timerState;
	let runningDuration = 0;
	if (timerState?.currentState === TimerRunningState.Running) {
		let st = timerState.startTime;
		let stDate = new AnandDate().setDateObj(new Date(st));
		let date = stDate.format('YYYY-MM-DD');
		if (props.mode.date && date === props.mode.date) {
			if (!props.mode.hour || stDate.hours() === props.mode.hour) {
				delete timerLogsByDate[date][st];
				runningDuration = timerState.duration - timerState.remainingTime;
			}
		}
	}

	let todayDurations = getDurationsByHour(timerLogsByDate, props.mode.date ?? today.format('YYYY-MM-DD'));

	let duration = runningDuration;
	if (props.mode.mode === Mode.Day) {
		duration += Object.values(todayDurations).reduce((p: any, c: any) => p + c, 0);
	} else if (props.mode.mode === Mode.Hour) {
		let scheduledHour = Math.floor(state.uxState.dhyanDaySchedule / 3600);
		duration += todayDurations[props.mode.hour - scheduledHour];
	}

	return { durationsByDate, dailyAvg, duration, goal: state.uxState.dhyanGoal * 60 };
})(_KPIMsg);

const dayBarClasses = {
	root: { display: 'flex', flexDirection: 'column', padding: '0 4px' },
	barWrap: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		pb: 0.5,
		alignSelf: 'center',
		height: 108,
	},
	bar: (duration, isCompleted, goal) => ({
		width: 15,
		height: (duration * 100) / goal + 'px',
		maxHeight: 100,
		background: isCompleted ? successColor : 'darkgrey',
	}),
};

const DayBar = (props) => {
	let { duration, date, onDateClick, goal } = props;
	let dateObj = new Date(date);
	let day = dateObj.getDay();

	let dayLetters = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	let dayLetter = dayLetters[day];

	let dayofMonth = dateObj.getDate();

	let isCompleted = duration >= goal;
	// let isPartial = duration >= goal / 2;

	let styles = dayBarClasses;

	return (
		<Box
			sx={styles.root}
			onClick={(e) => {
				e.stopPropagation();
				if (onDateClick) {
					onDateClick(date, duration, e);
				}
			}}
		>
			<Box sx={styles.barWrap}>
				{duration > goal ? (
					<>
						<Box sx={styles.bar(2 * goal - duration, isCompleted, goal)}></Box>
						<Box sx={styles.bar(duration - goal, isCompleted, goal)} style={{ background: successDarkColor }}></Box>
					</>
				) : (
					<Box sx={styles.bar(duration, isCompleted, goal)}></Box>
				)}
			</Box>
			<Box>
				<TimerPresetControl
					duration={dayofMonth}
					size={20}
					fontSize="1.2rem"
					background={!isCompleted ? 'lightgrey' : successColor}
					color={!isCompleted ? 'rgba(0, 0, 0, 0.6)' : '#ffffffc9'}
					fontWeight={700}
				/>
			</Box>
		</Box>
	);
};

const AllDaysChart = (props) => {
	let { durationsByDate, setMode, goal } = props;
	let sortedDates = Object.keys(durationsByDate).sort((a, b) => (a > b ? -1 : 1));

	let allDaysWrapRef = useRef<HTMLElement>();
	let maxDate = sortedDates[0];

	let [righDateInView, setRightDateInView] = useState<AnandDate>(new AnandDate(maxDate));
	let [leftDateInView, setLeftDateInView] = useState<AnandDate | undefined>(undefined);

	const setChartDateLabels = () => {
		let el = allDaysWrapRef.current;
		if (!el) {
			return;
		}

		let barWidth = 28;
		let visibleBars = el.clientWidth / barWidth;
		let scrolledBars = el.scrollLeft / barWidth;

		let leftDateDiff = Math.round(scrolledBars);
		let rightDateDiff = Math.round(scrolledBars + visibleBars - 1);

		let rightDate = new AnandDate(maxDate).subtract(rightDateDiff, 'day');
		let leftDate = new AnandDate(maxDate).subtract(leftDateDiff, 'day');

		setRightDateInView(rightDate);
		if (leftDate.month() === rightDate.month()) {
			setLeftDateInView(undefined);
		} else {
			setLeftDateInView(leftDate);
		}
	};

	useEffect(() => {
		setChartDateLabels();
	}, []);

	let continousDays = 0;

	return (
		<>
			<Box
				sx={classes.allDaysWrap}
				ref={allDaysWrapRef}
				onScroll={(e) => {
					setChartDateLabels();
				}}
			>
				{sortedDates.map((date, i) => {
					let isComplete = durationsByDate[date] >= goal;
					let isPrevComplete = i > 0 ? durationsByDate[sortedDates[i - 1]] >= goal : true;
					let isNextComplete = i < sortedDates.length - 1 && durationsByDate[sortedDates[i + 1]] >= goal;
					if (isComplete) {
						continousDays++;
					} else {
						continousDays = 0;
					}
					let showDays = isNextComplete === false && isComplete === true ? continousDays : 0;

					return (
						<Box key={date} sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ mb: -0.5 }}>
								{showDays > 1 ? (
									<Typography sx={{ ml: (-1 * (28 * showDays) + 28) / 8, textAlign: 'left', pl: 1.25 }} variant="subtitle2" color="primary">
										{showDays}
									</Typography>
								) : (
									<Typography variant="subtitle2">&nbsp;</Typography>
								)}
							</Box>
							<Box
								sx={{
									height: 2,
									width: 28 * showDays,
									ml: (-1 * (28 * showDays) + 28) / 8,
									background: showDays > 1 ? PrimaryMainColor : 'transparent',
								}}
							></Box>
							{/* <Box sx={{ height: 2, width: 28, background: continousDays > 1 ? PrimaryMainColor : 'transparent' }}></Box> */}
							<DayBar
								duration={durationsByDate[date]}
								date={date}
								onDateClick={(date, duration, e) => {
									setMode({ mode: Mode.Day, date: date });
								}}
								goal={goal}
							/>
						</Box>
					);
				})}
			</Box>
			<Box sx={{ px: 1, display: 'flex', flexDirection: 'row' }}>
				{!!leftDateInView && (
					<Typography variant="subtitle2" color="GrayText" fontSize={'1.2rem'} align="left" sx={{ flexGrow: 1 }}>
						{leftDateInView.format("MMM'YY")}
					</Typography>
				)}
				<Typography variant="subtitle2" color="GrayText" fontSize={'1.2rem'} sx={{ flexGrow: 1 }} align={!!leftDateInView ? 'right' : 'center'}>
					{righDateInView.format("MMM'YY")}
				</Typography>
			</Box>
		</>
	);
};

const _OneDayChart = (props) => {
	let { todayDurations, setMode, date, hour: selectedHour, dhyanDaySchedule } = props;
	let scheduleHour = Math.floor(dhyanDaySchedule / 3600);
	let markers = [scheduleHour, scheduleHour + 6, scheduleHour + 12, scheduleHour + 18];

	return (
		<>
			<Box sx={{ ...classes.allDaysWrap, direction: 'ltr', justifyContent: 'space-around' }}>
				{Object.keys(todayDurations).map((hour) => {
					let hr = parseInt(hour);
					let duration = todayDurations[hr];
					hr += Math.floor(dhyanDaySchedule / 3600);

					return (
						<Box key={hr}>
							{/* {hr % 6 === 0 && <Divider orientation="vertical" />} */}
							<Box
								sx={{ ...dayBarClasses.barWrap, height: 128 }}
								onClick={(e) => {
									e.stopPropagation();
									setMode({ mode: Mode.Hour, date: date, hour: hr });
								}}
							>
								<Box
									sx={{
										width: 5,
										height: (duration * 120) / (60 * 60) + 'px',
										maxHeight: 120,
										background: selectedHour === hr ? timerThemeColorDark : timerThemeColor,
									}}
								></Box>
							</Box>
						</Box>
					);
				})}
			</Box>
			<Box sx={{ px: 1, display: 'flex', flexDirection: 'row' }}>
				{markers.map((hr) => {
					return (
						<Typography
							key={hr}
							variant="subtitle2"
							color="GrayText"
							fontSize={'1.2rem'}
							sx={{ flexGrow: 1, width: 25 / markers.length + '%', ml: -0.5 }}
							align={'left'}
						>
							{new AnandDate().setHours(hr, 0, 0).format('-hhA')}
						</Typography>
					);
				})}
			</Box>
		</>
	);
};

function fstp(state: ApplicationState, props) {
	let timerLogsByDate = getTimerLogsByDate(state);
	let today = new AnandDate().setEpoch(state.tempState.today.getDateObj().getTime() - state.uxState.dhyanDaySchedule * 1000);

	let todayDurations = getDurationsByHour(timerLogsByDate, props.date ?? today.format('YYYY-MM-DD'));

	return {
		todayDurations,
		dhyanDaySchedule: state.uxState.dhyanDaySchedule,
	};
}

const OneDayChart = connect(fstp)(_OneDayChart);

const TrendChart = (props) => {
	let { mode, durationsByDate, setMode, goal } = props;

	let Chart = <></>;
	switch (mode.mode) {
		case Mode.All:
			Chart = <AllDaysChart durationsByDate={durationsByDate} setMode={setMode} goal={goal} />;
			break;
		case Mode.Day:
		case Mode.Hour:
			Chart = <OneDayChart setMode={setMode} date={mode.date} hour={mode.hour} goal={goal} />;
			break;
	}

	return Chart;
};

const Component = (props: TimerAnalyticsProps) => {
	let { durationsByDate, onDateClick, sx, isCustomMode, goal } = props;

	let defaultMode = { mode: Mode.All, date: undefined, hour: undefined };
	let [mode, _setMode] = useState(defaultMode);

	if (isCustomMode === false && mode.mode !== Mode.All) {
		_setMode(defaultMode);
	}

	const setMode = (val) => {
		_setMode(val);

		onDateClick(val.date ?? '');
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', ...sx }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
				<Typography variant="subtitle2" color="textPrimary" fontSize={'1.4rem'} sx={{ alignSelf: 'center' }}>
					{mode.mode !== Mode.All && (
						<IconButton
							sx={{ pl: 0 }}
							onClick={() => {
								setMode(defaultMode);
								// setTempCompleted(-1);
								// setTempDate('');
								// setIsCustomMode(false);
							}}
						>
							<Icons.ArrowBackIcon fontSize="small" />
						</IconButton>
					)}
				</Typography>
				<KPIMsg mode={mode} setMode={setMode} />
			</Box>
			<TrendChart mode={mode} durationsByDate={durationsByDate} setMode={setMode} goal={goal} />
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let durationsByDate = getTimerDurationByDate(state);

	return { durationsByDate, goal: state.uxState.dhyanGoal * 60 };
}

function mapDispatchToProps(dispatch) {
	return {};
}

export const TimerAnalytics = connect(mapStateToProps, mapDispatchToProps)(Component);
