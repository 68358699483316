import { Box, Divider, Typography } from '@mui/material';
import { getFormattedTimeFromSec } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import { connect } from 'react-redux';
import { getTimerLogsByDate } from 'store/data/timer/selectors';
import { ApplicationState } from 'types';
import { successColor, timerThemeColor } from './config';
import { CountDownTimer } from './CountDownTimer';

const classes = {
	root: (large) => ({
		width: large ? 'auto' : 150,
		textAlign: 'center',
	}),
	circle: (large) => ({
		margin: '0 auto',
		width: large ? 200 : 150,
	}),
};

interface Props {
	goal: number;
	completed: number;
	customDate?: string;
	customDuration?: number;
	sx?: any;
	size?: string;
}

const Component = (props: Props) => {
	let { goal, completed, customDate, customDuration, sx, size } = props;

	let today = new AnandDate().format('YYYY-MM-DD');

	let isPastDate = customDate?.length && customDate !== today;
	let duration = isPastDate ? customDuration ?? 0 : completed;

	let isLarge = size === 'large';

	return (
		<Box sx={{ ...classes.root(isLarge), ...sx }}>
			<Box sx={{ textAlign: 'left', mb: 1.5, mt: isLarge ? 0 : 1.5 }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flexGrow: 1,

						'& > .MuiTypography-root': {
							lineHeight: 1.2,
						},
					}}
				>
					<Typography sx={{ flexGrow: 1 }} variant={isLarge ? 'h6' : 'subtitle2'} color="textSecondary" align={'center'}>
						{!!customDate?.length ? new AnandDate(customDate).format("dd, 0DD MMM'YY") : 'TODAY'}
					</Typography>
				</Box>
			</Box>
			<Box sx={classes.circle(isLarge)}>
				<CountDownTimer
					duration={goal}
					remainingTime={Math.max(0, goal - duration)}
					colors={['#808080', timerThemeColor, successColor, successColor]}
					colorsTime={[goal, goal - 1, 2, 0]}
					size={isLarge ? 180 : 140}
				>
					{(color) => {
						return (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Typography variant="h5" color={color} sx={{ fontSize: isLarge ? '3rem' : '2.4rem' }}>
									{getFormattedTimeFromSec(duration)}
								</Typography>
								<Divider flexItem />
								<Typography variant="h6" color="textPrimary">
									{getFormattedTimeFromSec(goal, true)}
								</Typography>
							</Box>
						);
					}}
				</CountDownTimer>
			</Box>
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let timerLogsByDay = getTimerLogsByDate(state);
	let todayLogs = timerLogsByDay[new AnandDate().subtract(state.uxState.dhyanDaySchedule, 'second').format('YYYY-MM-DD')];
	let completed = todayLogs ? Object.values(todayLogs).reduce((prev, cur) => prev + cur.duration, 0) : 0;

	return {
		goal: state.uxState.dhyanGoal * 60,
		completed,
	};
}

export const TimerProgress = connect(mapStateToProps)(Component);
